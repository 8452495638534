/* eslint-disable react/jsx-filename-extension */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import 'antd/dist/antd.css'

import FacebookMessengerChat from './src/components/FacebookChatMessenger'

const wrapPageElement = ({ element }) => (
  <>
    {element}
    <FacebookMessengerChat />
  </>
)

const onRouteUpdate = () => {
  const headerElement = document.getElementById('header')
  if (headerElement) {
    headerElement.scrollIntoView()
  }
}

export { wrapPageElement, onRouteUpdate }
