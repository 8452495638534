import React, { useEffect, FC } from 'react'

// need to be updated with client facebook app id
const facebookAppId = '14451357057232322'
// list of url chat button will not show up
const optOutPages = [
  'https://staginggatsby.contegospa.com/vi',
  'https://www.contegospa.com/vi',
  'https://www.contegospa.com/why-disposable-jet',
  'https://www.contegospa.com/wecare',
  'https://www.contegospa.com/whycontego',
  'https://www.contegospa.com/digital-catalog',
]
// need to update for client
const LOGGED_IN_GREETING = 'Hi, welcome to ....! How can we help you?'
// need to update for client
const LOGGED_OUT_GREETING = 'Bye, Thank you for contacting us!!!'
// need to update for client
const THEME_COLOR = '#0084ff'

const FacebookMessengerChat: FC = () => {
  useEffect(() => {
    if (optOutPages.findIndex((value) => window.location.href.includes(value)) === -1) {
      window.fbAsyncInit = () => {
        window.FB.init({
          xfbml: true,
          version: 'v5.0',
        })
      }
      ;((d, s, id) => {
        let js = d.getElementsByTagName(s)[0]
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  }, [])

  return (
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id={facebookAppId}
        theme_color={`${THEME_COLOR}`}
        logged_in_greeting={`${LOGGED_IN_GREETING}`}
        logged_out_greeting={`${LOGGED_OUT_GREETING}`}
      />
    </>
  )
}

export default FacebookMessengerChat
